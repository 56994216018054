import Cookie from "@/core/services/localstorage.module";
export const get_fuel_provider_product = {
   "provider":[
      {
         "id_provider":"1",
         "name_provider":"Total",
         "logo":"total.png"
      },
      {
         "id_provider":"6",
         "name_provider":"Agil",
         "logo":"agil.png"
      },
      {
         "id_provider":"7",
         "name_provider":"Oilibya",
         "logo":"oilibya.png"
      },
      {
         "id_provider":"5",
         "name_provider":"Vivo Energy",
         "logo":"shell.png"
      }
   ],
   "product_cart":[
      {
         "id_card_product_type":"1",
         "name_card_product_type":"msg_fuel_all_product",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"2",
         "name_card_product_type":"msg_fuel_diesel",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"3",
         "name_card_product_type":"msg_fuel_great",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"4",
         "name_card_product_type":"msg_fuel_fuels",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"5",
         "name_card_product_type":"msg_fuel_GO_LAV",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"6",
         "name_card_product_type":"msg_fuel_GO_LAV_LUB_ENT",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"7",
         "name_card_product_type":"msg_fuel_GO_LAV_LUB",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"8",
         "name_card_product_type":"msg_fuel_GO_all_product",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"9",
         "name_card_product_type":"msg_fuel_SUP_LAV_LUB",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"10",
         "name_card_product_type":"msg_fuel_SUP_all_product",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"11",
         "name_card_product_type":"msg_fuel_CARB_LAV_LUB",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"12",
         "name_card_product_type":"msg_fuel_CARB_LAV_LUB_ENT",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"13",
         "name_card_product_type":"msg_fuel_WASHING",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"14",
         "name_card_product_type":"msg_fuel_LUBRICANTS",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"15",
         "name_card_product_type":"msg_fuel_TEMPS",
         "id_provider":"1"
      },
      {
         "id_card_product_type":"16",
         "name_card_product_type":"msg_fuel_all_product",
         "id_provider":"5"
      },
      {
         "id_card_product_type":"17",
         "name_card_product_type":"msg_fuel_all_product",
         "id_provider":"6"
      },
      {
         "id_card_product_type":"18",
         "name_card_product_type":"msg_fuel_all_product",
         "id_provider":"7"
      }
   ],
   "card_type":[
      {
         "id_card_type":"4",
         "name_card_type":"fleet card",
         "id_provider":"2"
      },
      {
         "id_card_type":"1500",
         "name_card_type":"msg_card_post_invoiced",
         "id_provider":"1"
      },
      {
         "id_card_type":"3500",
         "name_card_type":"msg_card_disposable_card",
         "id_provider":"1"
      },
      {
         "id_card_type":"2500",
         "name_card_type":"msg_card_pre_credited",
         "id_provider":"1"
      },
      {
         "id_card_type":"5",
         "name_card_type":"msg_AGILIS_Gold",
         "id_provider":"6"
      },
      {
         "id_card_type":"6",
         "name_card_type":"msg_AGILIS_Cash",
         "id_provider":"6"
      },
      {
         "id_card_type":"7",
         "name_card_type":"msg_Carte_Shell",
         "id_provider":"5"
      },
      {
         "id_card_type":"8",
         "name_card_type":"msg_OCard",
         "id_provider":"7"
      },
      {
         "id_card_type":"9",
         "name_card_type":"msg_OCard_Pr\u00e9pay\u00e9e",
         "id_provider":"7"
      }
   ],
   "product":[
      {
         "id_fuel_product":"1",
         "name_fuel_product":"GASOIL",
         "id_provider":"1"
      },
      {
         "id_fuel_product":"3",
         "name_fuel_product":"GASOIL 50",
         "id_provider":"1"
      },
      {
         "id_fuel_product":"2",
         "name_fuel_product":"SUPER SP",
         "id_provider":"1"
      },
      {
         "id_fuel_product":"5",
         "name_fuel_product":"Propane",
         "id_provider":"1"
      },
      {
         "id_fuel_product":"4",
         "name_fuel_product":"Gaz naturel comprim\u00e9 (GNC)",
         "id_provider":"1"
      },
      {
         "id_fuel_product":"6",
         "name_fuel_product":"Autre",
         "id_provider":"1"
      },
      {
         "id_fuel_product":"7",
         "name_fuel_product":"GASOIL SS",
         "id_provider":"1"
      },
      {
         "id_fuel_product":"8",
         "name_fuel_product":"SSP EXC",
         "id_provider":"1"
      }
   ],
   "card_status":[
      {
         "id_card_status":"1",
         "name_status":"Vierge"
      },
      {
         "id_card_status":"2",
         "name_status":"Valide"
      },
      {
         "id_card_status":"3",
         "name_status":"1 code faux"
      },
      {
         "id_card_status":"4",
         "name_status":"2 codes faux"
      },
      {
         "id_card_status":"5",
         "name_status":"3 codes faux"
      },
      {
         "id_card_status":"6",
         "name_status":"Invalid"
      },
      {
         "id_card_status":"7",
         "name_status":"Invalid\u00e9e par TPV"
      },
      {
         "id_card_status":"8",
         "name_status":"Transf\u00e9r\u00e9e"
      },
      {
         "id_card_status":"9",
         "name_status":"Restitu\u00e9e\n"
      },
      {
         "id_card_status":"10",
         "name_status":"Carte HS annul\u00e9e"
      },
      {
         "id_card_status":"11",
         "name_status":"Carte inactive annul\u00e9e"
      },
      {
         "id_card_status":"12",
         "name_status":"P\u00e9rim\u00e9e"
      },
      {
         "id_card_status":"13",
         "name_status":"Perdue"
      },
      {
         "id_card_status":"14",
         "name_status":"Active"
      }
   ]
};

function GetProvider(idProvider) {
	var res = {name:"",logo:""};
	get_fuel_provider_product.provider.forEach((resD)=>{
		if(idProvider == resD.id_provider){
			res["name"] = resD.name_provider;
			res["logo"] = resD.logo;
		}
	});
	return res;
}
function GetStatus(idStatus) {
	var res = {name:"",logo:""};
	get_fuel_provider_product.card_status.forEach((resD)=>{
		if(idStatus == resD.id_card_status){
			res["name"] = resD.name_status;
			res["logo"] = "";
		}
	});
	return res;
}
function GetProductCart(idProductCart) {
	var res = {name:"",id_provider:""};
	get_fuel_provider_product.product_cart.forEach((resD)=>{
		if(idProductCart == resD.id_card_product_type){
			res["name"] = resD.name_card_product_type;
			res["id_provider"] = resD.id_provider;
		}
	});
	return res;
}
function GetCardType(idCardType) {
	var res = {name:"",id_provider:""};
	get_fuel_provider_product.card_type.forEach((resD)=>{
		if(idCardType == resD.id_card_type){
			res["name"] = resD.name_card_type;
			res["id_provider"] = resD.id_provider;
		}
	});
	return res;
}
function GetInfoCars(key) {
	var res = {"id":0,"immatriculation":"","name":"","start_insurance":"","id_groupe":"","mdmid":"","name_insurance_company":null,"id_cust":"","name_constructeur":"","name_type_constructeur":"","name_type_motor":"","img_type_constructeur":"","img_constructeur":"","constructeur":"","type_constructeur":"","type_motor":""};
	if(key == 0 || key == "0" || key == "")
		return res;
	
	var mindata = Cookie.GetCookie('mindata');
	mindata.forEach((Mindata)=>{
		if(Mindata.id == key){
			res = Mindata;
		}
	});
	return res;
}



export default {
	GetProvider,GetStatus,GetProductCart,GetInfoCars,GetCardType
};

