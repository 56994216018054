<template>
	<div>
		<br>
        <div class="row">
			<!--begin::Aside-->
			<div class="col-3">
				<!--begin::Profile Card-->
				<div class="card card-custom ">
					<!--begin::Body-->
					<div class="card-body pt-4 pb-0">
						<h5 class="pb-5">Fuel : </h5>
						<div class="navi navi-bold navi-hover navi-active navi-link-rounded">
							<div class="navi-item mb-2" v-on:click="SelectRapport('dash')">
								<div href="" :class="RapportSelected == 'dash' ? 'navi-link py-4 active':'navi-link py-4'">
									<span class="navi-icon mr-2">
										<span class="svg-icon">
											<font-awesome-icon class="mr-2" :icon="['far', 'cars']"/>
										</span>
									</span>
									<span class="navi-text font-size-lg">Dashboard</span>
								</div>
							</div>
							<div class="navi-item mb-2" v-on:click="SelectRapport('CardList')">
								<div href="" :class="RapportSelected == 'CardList' ? 'navi-link py-4 active':'navi-link py-4'">
									<span class="navi-icon mr-2">
										<span class="svg-icon">
											<font-awesome-icon class="mr-2" :icon="['far', 'cars']"/>
										</span>
									</span>
									<span class="navi-text font-size-lg">Association carte-OBC</span>
								</div>
							</div>
							
						</div>
						<!--end::Nav-->
					</div>
					<!--end::Body-->
				</div>
				<!--end::Profile Card-->
			</div>
			<!--end::Aside-->
			<!--begin::Content-->
			<div class="col-9">
				<!--begin::Card-->
				<div class="card card-custom card-stretch p-4">
                    <DashFuel v-if="RapportSelected == 'dash'"></DashFuel>
                    <CardFuel v-if="RapportSelected == 'CardList'"></CardFuel>
				</div>
			</div>
			<!--end::Content-->
		</div>
		
	</div>
</template>

<script>
import DashFuel from "@/view/pages/fuel/Fuel.vue";
import CardFuel from "@/view/pages/fuel/Card.vue";


import { library } from '@fortawesome/fontawesome-svg-core'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { faCars as fasCars , faMap as fasMap , faRoad as fasRoad} from '@fortawesome/pro-solid-svg-icons'
import { faCars as farCars , faMap as farMap, faRoad as farRoad} from '@fortawesome/pro-regular-svg-icons'
library.add(fasCars,farCars,farMap,fasMap,fasRoad,farRoad)

export default {
	components: {
        DashFuel,CardFuel,
		'font-awesome-icon': FontAwesomeIcon,
    },
	data: () => ({
		RapportSelected:"dash",
    }),
	destroyed() {
		
	},
	computed: {
		
	},
	
	mounted() {

	},
	created() {

    },
	methods: {
		SelectRapport(type){
            this.RapportSelected = type;
        }

	},
}
</script>