<template>
  <div>
    <div class="row">
	  <div class="col-xxl-12">
	  <b-card>
      
         <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Sort"
          label-for="sort-by-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              size="sm"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>


    
    </b-row>

    <!-- Main table element -->
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
      <template #cell(card_number)="row">
        {{ row.item.card_number}}
      </template>
	  <template #cell(name)="row">
        {{ row.item.name }}
      </template>
	  <template #cell(id_car)="row">
		<div v-if="row.item.id_car != 0">
			{{propertiesFuel.GetInfoCars(row.item.id_car).name}}
			({{propertiesFuel.GetInfoCars(row.item.id_car).immatriculation}})
		</div>
		<b v-if="row.item.id_car == 0">
			Non associé
		</b>
      </template>
	  <template #cell(status)="row">
        {{propertiesFuel.GetStatus(row.item.status)["name"]}}
      </template>
		<template #cell(id_fuel_provider)="row">
			{{propertiesFuel.GetProvider(row.item.id_fuel_provider)["name"]}}
		</template>

      <template #cell(actions)="row">
        <b-button size="sm" @click="info(item, row.index, $event.target)" class="mr-1">
          Info modal
        </b-button>
      </template>
    </b-table>
	<b-row>
	 <b-col sm="3" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="9" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <!-- Info modal -->
    <b-modal size="xl" :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
		<b-card>
			<div class="table-responsive">
			   <table  class="table">
					<tbody>
						<tr>
							<td>N° carte</td>
							<td>:</td>
							<td v-if="infoModal.content">{{infoModal.content.card_number}}</td>
						</tr>
						<tr>
							<td>Nom carte</td>
							<td>:</td>
							<td v-if="infoModal.content">{{infoModal.content[0].name_card}}</td>
						</tr>
						<tr>
							<td>Etat</td>
							<td>:</td>
							<td v-if="infoModal.content">{{infoModal.content[0].status}}</td>
						</tr>
						<tr>
							<td>Type</td>
							<td>:</td>
							<td v-if="infoModal.content">{{propertiesFuel.GetCardType(infoModal.content[0].id_card_type).name}}</td>
						</tr>
						<tr>
							<td>Fournisseur</td>
							<td>:</td>
							<td v-if="infoModal.content"><img :src="'https://pro.sayarte.ch/2.6/assets/images/fuel/provider_logo/'+propertiesFuel.GetProvider(infoModal.content[0].id_fuel_provider)['logo']" style="width: 30px;">{{infoModal.content[0].name_provider}}</td>
						</tr>
						<tr>
							<td>Vehicule</td>
							<td>:</td>
							<td v-if="infoModal.content">
								{{infoModal.content}}
								{{propertiesFuel.GetInfoCars(infoModal.content[0].id_car).name}}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</b-card>
    </b-modal>
     
</b-card>
	 </div>

    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import propertiesFuel from "@/core/services/fuel.assets.module.js";
import axios from "axios";
import { mapGetters } from "vuex";

//import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
//import 'vue-datetime/dist/vue-datetime.css'
export default {
  name: "dashboard",
  components: {
	 //datetime: Datetime,
    
  },
  data: () => ({
	PFCTransactionImport : "",
	propertiesFuel : propertiesFuel,
	DashData : {},
	CarsData : {},
	chartOptions: {},
      series: [
        {
          name: "Revenue",
          data: [40, 70, 80, 60, 50, 65, 60]
        }
		
      ],
	  items: [],
        fields: [
          { key: 'card_number', label: 'N° carte', sortable: true, sortDirection: 'desc' },
          { key: 'name_card', label: 'Nom carte', sortable: true },
          { key: 'id_car', label: 'Véhicule', sortable: true},
          { key: 'creation_date', label: 'date de création', sortable: true },
          { key: 'validity_date', label: 'date de validité', sortable: true },
          { key: 'status', label: 'statut', sortable: true },
          { key: 'id_fuel_provider', label: 'Provider', sortable: true },
		  { key: 'actions', label: 'Actions' }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        },
		dateStart : "",
		dateEnd : "",
  }),
   computed: {
    
	sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
	  ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
	axios.defaults.headers["Content-Type"] =  "application/json";
	axios.defaults.withCredentials = true;
	
	this.dateStart = new Date(new Date().setDate(1)).toJSON();
	this.dateEnd = new Date().toJSON();
	this.GetDashboard();
	this.totalRows = this.items.length
	//////
	this.$forceUpdate();

  },
  methods: {
	info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = item//JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    OpenModelAddTran() {
	  this.$refs['FuelAddTra'].show();
    },
	hideModalDataCars() {
      this.$refs['FuelAddTra'].hide();
    },
	sendFilesFuel(){
		let files = this.$refs.file.files
		console.log(this.$refs.file);
		const formData = new FormData();
		formData.append("file", files);
		formData.append("ProviderfuelCarteImport", this.PFCTransactionImport);
		axios.post("controleur/fuel/import_all_card.php", formData).then(function (result) {
			console.log(result);
		}, function (error) {
			console.log(error);
		});
	},
	GetDashboard(){
		this.items = [];
		axios.get("controleur/fuel/get_fuel_card.php?id=all").then((result)=> {
			this.items = result.data
			this.totalRows = result.data.length
		}, function (error) {
			console.log(error);
		});
	},
	setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
