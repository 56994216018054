<template>
  <div>
	<div id="kt_subheader" class="subheader py-3 py-lg-8 subheader-transparent transparent">
	   <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap container">
					<div class="d-flex align-items-center">
							<div class="row">
								<div class="col-md-4">
								</div>
								<div class="col-md-3">
									<datetime type="date" :placeholder="$t('TEXT_ALL.DATE_START')" v-model="dateStart" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
								</div>
								<div class="col-md-3">
									<datetime type="date" :placeholder="$t('TEXT_ALL.DATE_END')" v-model="dateEnd" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
								</div>
								<div class="col-md-2">
									<button type="submit" class="btn btn-success font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" @click="GetTrajet();"> Appliquer </button>
								</div>
							</div>
				  
				  
					 <a href="#" @click="OpenModelAddTran()" class="btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
						<span class="svg-icon svg-icon-primary svg-icon-lg">

						</span>
						Import Transactions
					 </a>
					 <a href="#" class="btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
						<span class="svg-icon svg-icon-primary svg-icon-lg">

						</span>
						Création carte
					 </a>

				  </div>
	   </div>
	</div>
	<b-modal size="xl"  ref="FuelAddTra" :title="Add" hide-footer>
		<form @submit.prevent="sendFilesFuel" id="uploadCSVTransactionCard"  ref="uploadCSVTransactionCard" method="post" enctype="multipart/form-data">
			<div class="row">
				<div class="col-sm-3">
					<label class="col-sm-3">Importer</label>
				</div>
				<div class="col-sm-3">
					<select name="PFCTransactionImport" v-model="PFCTransactionImport" class="form-control"  required="">
						<option value="">-</option>
						<option value="1">Total</option>
						<option value="6">Agil</option>
						<option value="7">Oilibya</option>
						<option value="5">Vivo Energy</option>
					 </select>
				</div>
				<div class="col-sm-4">
					<input type="file" name="file" ref="file" class="form-control" required="">
				</div>
				<div class="col-sm-2">
					<!--<input type="submit" value="Importer" class="btn btn-primary" />-->
					<button type="submit" class="btn btn-default">Importer</button>
				</div>
			</div>
		</form>
		<footer class="modal-footer">
			<button type="button" class="btn btn-secondary" @click="hideModalDataCars();">Annuler</button>
			<button type="submit" class="btn btn-primary" @click="sendFilesFuel()">OK</button>
		</footer>
	</b-modal>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-xxl-4">
	  {{DashData.card}}
          <div
    class="card card-custom bg-radial-gradient-primary gutter-b card-stretch"
  >
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder text-white">Carburant </h3>
      <div class="card-toolbar">
	 
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <!--begin::Chart-->

      <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="bar"
      ></apexchart>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer bg-white card-rounded flex-grow-1">
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col px-8 py-6 mr-8">
            <div class="font-size-sm text-muted font-weight-bold">
              Average Sale
            </div>
            <div class="font-size-h4 font-weight-bolder">$650</div>
          </div>
          <div class="col px-8 py-6">
            <div class="font-size-sm text-muted font-weight-bold">
              Commission
            </div>
            <div class="font-size-h4 font-weight-bolder">$233,600</div>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col px-8 py-6 mr-8">
            <div class="font-size-sm text-muted font-weight-bold">
              Annual Taxes
            </div>
            <div class="font-size-h4 font-weight-bolder">$29,004</div>
          </div>
          <div class="col px-8 py-6">
            <div class="font-size-sm text-muted font-weight-bold">
              Annual Income
            </div>
            <div class="font-size-h4 font-weight-bolder">$1,480,00</div>
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
      </div>
	  <div class="col-xxl-8">
	  <b-card>
      
         <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Sort"
          label-for="sort-by-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              size="sm"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>


    
    </b-row>

    <!-- Main table element -->
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
      <template #cell(num)="row">
        {{ row.item.num}}
      </template>
	  <template #cell(name)="row">
        {{ row.item.name }}
      </template>
	  <template #cell(car)="row">
        {{ row.item.car }}
      </template>

      <template #cell(actions)="row">
        <b-button size="sm" @click="info(CarsData[row.item.num], row.index, $event.target)" class="mr-1">
          Info modal
        </b-button>
        <b-button size="sm" @click="row.toggleDetails">
          {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
        </b-button>
      </template>

      <template #row-details="row">
		<div class="table-responsive">
		   <table  class="table">
				<tbody>
					<tr>
						<td>N° carte</td>
						<td>:</td>
						<td v-if="CarsData[row.item.num]">{{CarsData[row.item.num][0].num_card}}</td>
					</tr>
					<tr>
						<td>Nom carte</td>
						<td>:</td>
						<td v-if="CarsData[row.item.num].content">{{CarsData[row.item.num][0].name_card}}</td>
					</tr>
					<tr>
						<td>Etat</td>
						<td>:</td>
						<td v-if="CarsData[row.item.num]">{{CarsData[row.item.num][0].name_status}}</td>
					</tr>
					<tr>
						<td>Restriction</td>
						<td>:</td>
						<td v-if="CarsData[row.item.num]">{{CarsData[row.item.num][0].name_fuel_product}}</td>
					</tr>
					<tr>
						<td>Fournisseur</td>
						<td>:</td>
						<td v-if="CarsData[row.item.num]"><img :src="'https://pro.sayarte.ch/2.6/assets/images/fuel/provider_logo/'+CarsData[row.item.num][0].logo" style="width: 30px;">{{CarsData[row.item.num][0].name_provider}}</td>
					</tr>
					<tr>
						<td>name_card_type</td>
						<td>:</td>
						<td v-if="CarsData[row.item.num]">{{CarsData[row.item.num][0].name_card_type}}</td>
					</tr>
				</tbody>
			</table>
			<hr>
		  <table  class="table table-striped">
				<thead>
					<tr>
						<!--<th>name_card</th >-->
						<!--<th>name_status</th >-->
						<!--<th>name_fuel_product</th >-->
						<!--<th>name_provider</th >-->
						<th>id_transaction</th>
						<th>date_transaction</th>
						<th>num_ticket</th>
						<th>type_operation</th>
						<!--<th>product_code</th>-->
						<!--<th>unit_price</th>-->
						<th>quantity</th>
						<th>amount</th>
						<th>name_station</th>
						<!--<th>id_card_type</th>-->
						<!--<th>name_card_type</th>-->
					</tr>
				</thead>
				<tbody>
					<tr v-for="(value, key) in CarsData[row.item.num]" :key="key">
						<!--<td>{{ value.name_card }}</td>-->
						<!--<td>{{ value.name_status }}</td>-->
						<!--<td>{{ value.name_fuel_product }}</td>-->
						<!--<td>{{ value.name_provider }}</td>-->
						<td>{{ value.id_transaction }}</td>
						<td>{{ value.date_transaction }}</td>
						<td>{{ value.num_ticket }}</td>
						<td>{{ value.type_operation }}</td>
						<!--<td>{{ value.product_code }}</td>-->
						<!--<td>{{ value.unit_price }}</td>-->
						<td>{{ value.quantity }}</td>
						<td>{{ value.amount }}</td>
						<td>{{ value.name_station }}</td>
						<!--<td>{{ value.id_card_type }}</td>-->
						<!--<td>{{ value.name_card_type }}</td>-->
					</tr>
				</tbody>
			
			</table>
		</div>
        
      </template>
    </b-table>
	<b-row>
	 <b-col sm="3" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="9" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <!-- Info modal -->
    <b-modal size="xl" :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
		<b-card>
			<div class="table-responsive">
			   <table  class="table">
					<tbody>
						<tr>
							<td>N° carte</td>
							<td>:</td>
							<td v-if="infoModal.content">{{infoModal.content[0].num_card}}</td>
						</tr>
						<tr>
							<td>Nom carte</td>
							<td>:</td>
							<td v-if="infoModal.content">{{infoModal.content[0].name_card}}</td>
						</tr>
						<tr>
							<td>Etat</td>
							<td>:</td>
							<td v-if="infoModal.content">{{infoModal.content[0].name_status}}</td>
						</tr>
						<tr>
							<td>Restriction</td>
							<td>:</td>
							<td v-if="infoModal.content">{{infoModal.content[0].name_fuel_product}}</td>
						</tr>
						<tr>
							<td>Fournisseur</td>
							<td>:</td>
							<td v-if="infoModal.content"><img :src="'https://pro.sayarte.ch/2.6/assets/images/fuel/provider_logo/'+infoModal.content[0].logo" style="width: 30px;">{{infoModal.content[0].name_provider}}</td>
						</tr>
						<tr>
							<td>name_card_type</td>
							<td>:</td>
							<td v-if="infoModal.content">{{infoModal.content[0].name_card_type}}</td>
						</tr>
					</tbody>
				</table>
				<hr>
			  <table  class="table table-striped">
					<thead>
						<tr>
							<!--<th>name_card</th >-->
							<!--<th>name_status</th >-->
							<!--<th>name_fuel_product</th >-->
							<!--<th>name_provider</th >-->
							<th>id_transaction</th>
							<th>date_transaction</th>
							<th>num_ticket</th>
							<th>type_operation</th>
							<!--<th>product_code</th>-->
							<!--<th>unit_price</th>-->
							<th>quantity</th>
							<th>amount</th>
							<th>name_station</th>
							<!--<th>id_card_type</th>-->
							<!--<th>name_card_type</th>-->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(value, key) in infoModal.content" :key="key">
							<!--<td>{{ value.name_card }}</td>-->
							<!--<td>{{ value.name_status }}</td>-->
							<!--<td>{{ value.name_fuel_product }}</td>-->
							<!--<td>{{ value.name_provider }}</td>-->
							<td>{{ value.id_transaction }}</td>
							<td>{{ value.date_transaction }}</td>
							<td>{{ value.num_ticket }}</td>
							<td>{{ value.type_operation }}</td>
							<!--<td>{{ value.product_code }}</td>-->
							<!--<td>{{ value.unit_price }}</td>-->
							<td>{{ value.quantity }}</td>
							<td>{{ value.amount }}</td>
							<td>{{ value.name_station }}</td>
							<!--<td>{{ value.id_card_type }}</td>-->
							<!--<td>{{ value.name_card_type }}</td>-->
						</tr>
					</tbody>
				
				</table>
			</div>
		</b-card>
    </b-modal>
     
</b-card>
	 </div>
      <div class="col-xxl-4">
        <StatsWidget7></StatsWidget7>
        <StatsWidget12></StatsWidget12>
      </div>
      <div class="col-xl-8">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div>

      <div class="col-lg-12 col-xxl-12">
        <ListWidget3></ListWidget3>
      </div>

      <div class="col-xxl-6">
        <ListWidget14></ListWidget14>
      </div>
      <div class="col-xxl-6">
        <ListWidget15></ListWidget15>
      </div>

      <div class="col-xxl-4">
        <ListWidget8></ListWidget8>
      </div>
      <div class="col-xxl-8">
        <ListWidget2></ListWidget2>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
//import MixedWidget1 from "@/view/pages/fuel/content/Widget1.vue";
import ListWidget2 from "@/view/content/widgets/list/Widget2.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
//import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
import ListWidget14 from "@/view/content/widgets/list/Widget14.vue";
import ListWidget15 from "@/view/content/widgets/list/Widget15.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
export default {
  name: "dashboard",
  components: {
	 datetime: Datetime,
    AdvancedTableWidget2,
    //MixedWidget1,
    ListWidget2,
    ListWidget3,
    ListWidget8,
    //ListWidget9,
    ListWidget14,
    ListWidget15,
    StatsWidget7,
    StatsWidget12
  },
  data: () => ({
	PFCTransactionImport : "",
	DashData : {},
	CarsData : {},
	chartOptions: {},
      series: [
        {
          name: "Revenue",
          data: [40, 70, 80, 60, 50, 65, 60]
        }
		
      ],
	  
	  
	  
	  items: []/*[
          { isActive: true, age: 40, name: { first: 'Dickerson', last: 'Macdonald' } },
          { isActive: false, age: 21, name: { first: 'Larsen', last: 'Shaw' } },
          {
            isActive: false,
            age: 9,
            name: { first: 'Mini', last: 'Navarro' },
            _rowVariant: 'success'
          },
          { isActive: false, age: 89, name: { first: 'Geneva', last: 'Wilson' } },
          { isActive: true, age: 38, name: { first: 'Jami', last: 'Carney' } },
          { isActive: false, age: 27, name: { first: 'Essie', last: 'Dunlap' } },
          { isActive: true, age: 40, name: { first: 'Thor', last: 'Macdonald' } },
          {
            isActive: true,
            age: 87,
            name: { first: 'Larsen', last: 'Shaw' },
            _cellVariants: { age: 'danger', isActive: 'warning' }
          },
          { isActive: false, age: 26, name: { first: 'Mitzi', last: 'Navarro' } },
          { isActive: false, age: 22, name: { first: 'Genevieve', last: 'Wilson' } },
          { isActive: true, age: 38, name: { first: 'John', last: 'Carney' } },
          { isActive: false, age: 29, name: { first: 'Dick', last: 'Dunlap' } }
        ]*/,
        fields: [
          { key: 'num', label: 'N° carte', sortable: true, sortDirection: 'desc' },
          { key: 'name', label: 'Nom carte', sortable: true },
          { key: 'car', label: 'Véhicule', sortable: true},
          { key: 'creation_date', label: 'date de création', sortable: true },
          { key: 'validity_date', label: 'date de validité', sortable: true },
		  { key: 'actions', label: 'Actions' }
		  
		  
		  

		  
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        },
		dateStart : "",
		dateEnd : "",
  }),
   computed: {
    
	sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
	  ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
	axios.defaults.headers["Content-Type"] =  "application/json";
	axios.defaults.withCredentials = true;
	
	this.dateStart = new Date(new Date().setDate(1)).toJSON();
	this.dateEnd = new Date().toJSON();
	this.GetDashboard();
	this.totalRows = this.items.length
	//////
	this.$forceUpdate();
    this.chartOptions = {
          series: [{
          name: 'Inflation',
          data: [2.3, 3.1, 4.0, 10.1, 4.0]
        }],
          chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#ffffff"]
          }
        },
        
        xaxis: {
          categories: ["GASOIL SS", "GASOIL", "Autre", "SUPER SP", "SSP EXC"],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#fff',
                colorTo: '#fff',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            }
          }
        
        },
        title: {
          text: 'Monthly Inflation in Argentina, 2002',
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        }
        };
  },
  methods: {
	info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = item//JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    OpenModelAddTran() {
	  this.$refs['FuelAddTra'].show();
    },
	hideModalDataCars() {
      this.$refs['FuelAddTra'].hide();
    },
	sendFilesFuel(){
		let files = this.$refs.file.files
		console.log(this.$refs.file);
		const formData = new FormData();
		formData.append("file", files);
		formData.append("ProviderfuelCarteImport", this.PFCTransactionImport);
		axios.post("controleur/fuel/import_all_card.php", formData).then(function (result) {
			console.log(result);
		}, function (error) {
			console.log(error);
		});
	},
	GetDashboard(){
		//var post = {start : "2018/10/15+00:00:00", end  : "2023/10/15+00:00:00", car  : ""};
		const formData = new FormData();
		//formData.append("start", "2018/10/15+00:00:00");
		//formData.append("end", "2023/10/15+00:00:00");
		formData.append("start", this.dateStart.replace("T","+").split('.')[0]);
		formData.append("start", "2018/10/15+00:00:00");//remove frome test
		formData.append("end", this.dateEnd.replace("T","+").split('.')[0]);
		
		
		formData.append("car", "");
		axios.post("controleur/fuel/dashboard.php", formData).then((result)=> {
			this.DashData = result.data.abstract;
			this.CarsData = [];
			for(var i in result.data.detail.card){
				var id = result.data.detail.card[i].card_number
				if(typeof(this.CarsData[id.trim()]) == "undefined")
					this.CarsData[id.trim()] = [];
				this.CarsData[id.trim()].push(result.data.detail.card[i])
			}
			this.items = []
			
			for(var t in this.CarsData){
				this.items.push({num:t,name:this.CarsData[t][0].name_card,car:this.CarsData[t][0].id_car,creation_date:this.CarsData[t][0].creation_date,validity_date:this.CarsData[t][0].validity_date})
			}
			this.totalRows = this.items.length
			console.log("6666666666666666")
			console.log(this.items)
		}, function (error) {
			console.log(error);
		});
	},
	setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
